<template>
  <div :class="$style.container">
    <div :class="$style.responsive">
      <table :class="$style.tabel">
        <thead :class="$style.header">
          <tr>
            <th></th>
            <th v-for="(row, index) in rows" :key="index">
              {{ row.label }}
            </th>
          </tr>
        </thead>
        <Container
          tag="tbody"
          :class="$style.body"
          @drop="onDrop"
          @drag-start="drag = false"
          @drag-end="drag = true"
        >
          <Draggable
            tag="tr"
            v-for="(item, index) in list"
            :key="index"
            :class="$style.row"
          >
            <td :class="[$style.draggable, $style.col]">
              <Icon name="draggable" :class="$style.icon" />
            </td>
            <template v-for="(row, index) in rows">
              <td :key="index" :class="$style.col">
                <slot v-if="row.isSlot" :row="row" :item="item" />
                <span v-else>
                  {{ item[row.field] }}
                </span>
              </td>
            </template>
          </Draggable>
        </Container>
      </table>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'

export default {
  components: { Icon },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      drag: false,
      isDropResult: false,
      list: [],
      debounceTimeout: null
    }
  },
  watch: {
    async drag() {
      if (this.drag && this.isDropResult) {
        await this.debounce(() => {
          this.updateOrders()
          this.isDropResult = false
          this.$emit('drag', this.list)
        }, 2000)
      }
    },
    items() {
      this.list = this.items
    }
  },
  methods: {
    debounce(callback, ms) {
      return ((...args) => {
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.debounceTimeout = null
          callback(...args)
        }, ms)
      })()
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    updateOrders() {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].order = i + 1
      }
    },
    onDrop(dropResult) {
      document.body.classList.remove('smooth-dnd-no-user-select')
      document.body.classList.remove('smooth-dnd-disable-touch-action')
      this.list = this.applyDrag(this.list, dropResult)
      this.isDropResult = true
    }
  }
}
</script>

<style lang="scss" module>
.container {
  overflow-x: auto;
  .responsive {
    border-radius: 0.25rem;
    @include tabelShadow;
    width: 100%;
    min-width: 81.25rem;
    overflow-x: auto;
    .tabel {
      width: 100%;
      table-layout: auto;
      border: 1px solid $tabel-border;
      .header {
        tr {
          background-color: $tabel-header-bgc;
        }
        th {
          color: $tabel-th-color;
          font-weight: 700;
          text-align: left;
          padding: 0.75rem;
          border-bottom: 1px solid $tabel-border;
        }
      }
      .col {
        padding: 0.75rem;
      }
      .row {
        width: 100%;
        border-bottom: 1px solid $tabel-border;
        .draggable {
          min-width: 3rem;
          min-height: 3rem;
          height: 100%;
          cursor: move;
          position: relative;
          .icon {
            width: 1.5rem;
            height: 1.5rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &:nth-child(odd) {
          background-color: $bgc-tabel-row;
        }
      }
    }
  }
}
</style>

<style scoped>
.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  display: table-row;
}
.smooth-dnd-no-user-select
  .smooth-dnd-container.vertical
  > .smooth-dnd-draggable-wrapper {
  display: table-row;
  width: 100%;
}

.smooth-dnd-disable-touch-action
  .smooth-dnd-container.vertical
  > .smooth-dnd-draggable-wrapper {
  display: table-row;
  width: 100%;
}

.smooth-dnd-draggable-wrapper.animated {
  display: block;
  width: 100%;
}
</style>
