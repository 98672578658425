<template>
  <div :class="$style.container">
    <h1>Обучающие видеоматериалы</h1>
    <ul :class="$style.filter">
      <li
        :class="[$style.item, { [$style.active]: true === activeFilters }]"
        @click="
          activeFilters = true
          getList()
        "
      >
        Активные
      </li>
      <li
        :class="[$style.item, { [$style.active]: false === activeFilters }]"
        @click="
          activeFilters = false
          getList()
        "
      >
        Неактивные
      </li>
      <li
        :class="[$style.item, { [$style.active]: null === activeFilters }]"
        @click="
          activeFilters = null
          getList()
        "
      >
        Все
      </li>
    </ul>
    <Loader :isLoading="isLoading" />
    <modal
      :class="$style.modal"
      name="edit-instructional-video-modal"
      scrollable
      height="auto"
      @closed="clearForm"
    >
      <form :class="$style.form" @submit.prevent="updateVideo">
        <Icon
          name="plus"
          :class="$style.plus"
          @click="$modal.hide('edit-instructional-video-modal')"
        ></Icon>
        <h2>Изменить видео</h2>
        <Input
          v-model="form.title"
          :class="$style.input"
          placeholder="Название видео"
        />
        <Input
          v-model="form.url"
          :class="$style.input"
          placeholder="Ссылка на видео"
        />
        <label class="label" :class="$style.checkbox">
          Активный:
          <input v-model="form.isActive" type="checkbox" />
        </label>
        <br />
        <Button type="submit">Редактировать </Button>
        <div v-if="error" :class="$style.error">
          {{ error }}
        </div>
      </form>
    </modal>
    <button :class="$style.create" @click="isCreateFormShow = true">
      <Icon role="button" name="plus" :class="$style.plus" /> Добавить видео
    </button>
    <form
      v-if="isCreateFormShow"
      :class="$style.form"
      @submit.prevent="createVideo"
    >
      <Icon
        name="plus"
        :class="$style.plus"
        @click="isCreateFormShow = false"
      ></Icon>
      <h2>Создать видео</h2>
      <Input
        v-model="form.title"
        :class="$style.input"
        placeholder="Название видео"
      />
      <Input
        v-model="form.url"
        :class="$style.input"
        placeholder="Ссылка на видео"
      />
      <label class="label" :class="$style.checkbox">
        Активный:
        <input v-model="form.isActive" type="checkbox" />
      </label>
      <br />
      <Button type="submit">Создать </Button>
      <div v-if="error" :class="$style.error">
        {{ error }}
      </div>
    </form>
    <DraggableTable :rows="rows" :items="videos" @drag="handleDrag($event)">
      <template v-slot:default="slotProps">
        <td v-if="slotProps.row.field === 'url'">
          <div :class="$style.video">
            <iframe
              height="100%"
              width="100%"
              :src="slotProps.item.url"
              :title="slotProps.item.title"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </td>
        <td v-else-if="slotProps.row.field === 'edit'">
          <Button
            small
            @click="
              $modal.show('edit-instructional-video-modal'),
                (modalVideoId = slotProps.item.id),
                getById()
            "
          >
            Редактировать
          </Button>
        </td>
        <td v-else-if="slotProps.row.field == 'delete'">
          <span title="Удалить акцию" @click="deleteVideo(slotProps.item)">
            <Icon name="plus" :class="$style.delete" />
          </span>
        </td>
      </template>
    </DraggableTable>
    <Button
      v-if="page < lastPage"
      :class="$style.loadMore"
      type="submit"
      @click="loadMore"
    >
      Загрузить еще
    </Button>
  </div>
</template>

<script>
import regexp from '@/helpers/regexp.js'
import delivery from '@/delivery'
import Icon from '@/components/atoms/Icon'
import Input from '@/components/atoms/Input.vue'
import Button from '@/components/atoms/Button.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import DraggableTable from '@/components/moleculs/DraggableTable.vue'

export default {
  components: {
    Icon,
    Input,
    Button,
    Loader,
    DraggableTable,
  },
  async created() {
    await this.getList(true)
  },
  data() {
    return {
      isCreateFormShow: false,
      videos: [],
      page: 1,
      limit: 10,
      lastPage: 0,
      isLoading: false,
      error: '',
      form: {
        title: '',
        url: '',
        isActive: false,
      },
      modalVideoId: '',
      activeFilters: true,
      rows: [
        {
          label: 'Видео',
          field: 'url',
          isSlot: true,
        },
        {
          label: 'Название',
          field: 'title',
        },
        {
          label: '',
          field: 'edit',
          isSlot: true,
        },
        {
          label: '',
          field: 'delete',
          isSlot: true,
        },
      ],
    }
  },
  methods: {
    async getList() {
      this.isLoading = true
      const query = {
        limit: this.limit,
        page: this.page,
        active: this.activeFilters,
      }
      const { value, error } =
        await delivery.AddwineCore.InstructionalVideosActions.getList(query)
      if (error) return
      this.videos = value.data
      this.lastPage = Math.ceil(value.meta.count / this.limit)
      this.isLoading = false
    },
    async getById() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.InstructionalVideosActions.getById(
          this.modalVideoId,
        )
      if (error) return
      this.form.title = value.title
      this.form.url = value.url
      this.form.isActive = value.isActive
      this.isLoading = false
    },
    async updateVideo() {
      this.isLoading = true
      const result =
        await delivery.AddwineCore.InstructionalVideosActions.updateVideo(
          this.modalVideoId,
          this.form,
        )
      if (result.error) {
        alert('Ошибка обновления видео, попробуйте ещё раз!')
        return
      } else {
        this.$modal.hide('edit-instructional-video-modal')
        this.getList()
      }
      this.isLoading = false
    },
    checkVideoLink() {
      if (regexp.url.test(this.form.url)) {
        if (this.form.url.includes('youtube')) {
          this.form.url = this.form.url.replace('watch?v=', 'embed/')
        }
        if (this.form.url.includes('rutube')) {
          this.form.url = this.form.url.replace('video', 'embed')
        }
        return true
      } else {
        alert('Ссылка должна начинаться с http://... или https://...')
        return false
      }
    },
    clearForm() {
      this.form.url = ''
      this.form.title = ''
      this.form.isActive = false
      this.modalVideoId = ''
    },
    async createVideo() {
      this.isLoading = true
      if (this.checkVideoLink()) {
        const result =
          await delivery.AddwineCore.InstructionalVideosActions.createVideo(
            this.form,
          )
        if (result.error) {
          console.error(result.error)
          alert('Ошибка создания видео!')
        } else {
          this.clearForm()
          this.isCreateFormShow = false
          this.getList()
        }
      }
      this.isLoading = false
    },
    async deleteVideo(item) {
      if (confirm(`Вы уверены, что хотите удалить видео ${item.title} ?`)) {
        this.isLoader = true

        const result =
          await delivery.AddwineCore.InstructionalVideosActions.deleteVideo(
            item.id,
          )
        if (result.error) {
          console.error(result.error)
          alert('Ошибка удаления видео!')
        } else {
          this.getList()
        }
        this.isLoader = false
      }
    },
    openModalEditVideo(id) {
      this.idVideo = id
      this.getById()
    },
    async updateInstructionalVideos() {
      this.isLoading = true
      const result =
        await delivery.AddwineCore.InstructionalVideosActions.updateInstructionalVideos(
          { instructional_videos: this.videos },
        )
      if (result.error) return
      this.isLoading = false
    },
    async loadMore() {
      this.page++
      const prevArr = this.videos
      await this.getList()
      this.videos = [...prevArr, ...this.videos]
    },
    async handleDrag($event) {
      this.videos = $event
      await this.updateInstructionalVideos()
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  .filter {
    display: flex;
    margin-bottom: 2rem;
    padding: 0;
    .item {
      margin-right: 1.5rem;
      color: $light-gray;
      cursor: pointer;
      position: relative;
      list-style-type: none;
      &.active {
        color: $black-gray;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -0.5rem;
          height: 0.25rem;
          width: 100%;
          background: $black-gray;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .create {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid $light-gray;
    margin: 2rem 0;
    cursor: pointer;
    gap: 0.5rem;
    text-decoration: none;
    color: $black-gray;
    .plus {
      fill: $black-gray;
      width: 1rem;
      height: 1rem;
    }
    &:hover {
      background: $white;
    }
  }
  .modal {
    .form {
      margin: 0 auto;
      .checkbox {
        display: inline-block;
        margin-bottom: 1rem;
      }
    }
  }
  .form {
    background: $white;
    padding: 2rem;
    max-width: 30rem;
    margin-bottom: 3rem;
    position: relative;
    h2 {
      color: $black-gray;
      margin-bottom: 1.5rem;
    }
    .input {
      width: 100%;
      margin-bottom: 1.5rem;
    }
    .plus {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      transform: rotate(45deg);
    }
    .checkbox {
      display: inline-block;
      margin-bottom: 1rem;
    }
  }
  .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 2rem;
  }
  .delete {
    fill: $light-gray;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    transform: rotate(45deg);
  }

  .video {
    width: 15rem;
    height: 10rem;
  }

  .loadMore {
    display: flex;
    margin: auto;
    margin-top: 2rem;
  }
}
</style>
